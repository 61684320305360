import React from "react";

export const BackToTop = () => {
  return (
    <>
      <a href="#" className="back-to-top">
        <ion-icon name="arrow-up-outline"></ion-icon>
      </a>
    </>
  );
};
