import React from "react";
import { Navbar } from "../Navbar";
import { BackToTop } from "../BackToTop";

export const AdminDashboard = () => {
  return (
    <div className="wrapper">
      <Navbar />
      <div className="page-content-wrapper">
        <div className="page-content">
          {/* <!--start breadcrumb--> */}
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">Pages</div>
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0 align-items-center">
                  <li className="breadcrumb-item active" aria-current="page">
                    Admin Dashboard
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* <!--end breadcrumb--> */}

          {/* <div className="card radius-10">
            <div className="card-body">
              <h4>Dashobard</h4>                
              
            </div>
          </div> */}

          <div className="card">
            <div className="card-body">
              <p>Dashboard</p>
            </div>
          </div>
        </div>
      </div>
      <BackToTop />
    </div>
  );
};
