import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import "./constants";
import axios from "axios";
import Swal from "sweetalert2";
import { Navbar } from "../Navbar";
import { BackToTop } from "../BackToTop";
import { TEAM_LISTALL, TEAM_SAVE } from "./constants";
import { useNavigate } from "react-router-dom";

export const Teams = () => {
  const navigate = useNavigate;

  const [show, setShow] = useState(false);
  const [allteams, setAllteams] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [teamid, setTeamid] = useState(0);
  const [userdata, setUserdata] = useState([]);

  let loginUserData = "";

  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };

  const saveWorkorder = () => {
    var postData = {
      id: teamid,
      name: teamName,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };
    axios
      .post(TEAM_SAVE, postData, axiosConfig)
      .then((result) => {
        var res = result.data;
        if (res.status === false) {
          Swal.fire(res.msg);
          return false;
        }
        console.log("RESPONSE RECEIVED: ", res);
        Swal.fire(res.msg);
        setTeamName("");
        setTeamid(0);

        hideModal();
        getAllTeams();
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  const getAllTeams = () => {
    var postData = {
      tenant: 1,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };
    axios
      .post(TEAM_LISTALL, postData, axiosConfig)
      .then((result) => {
        var res = result.data;

        setAllteams(res);
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };
  useEffect(() => {
    loginUserData = JSON.parse(sessionStorage.getItem("userData"));
    if (!loginUserData) {
      navigate("/login");
    }

    // setUserdata(loginUserData);
    setTimeout(getAllTeams(), 1500);
  }, []);
  return (
    <div className="wrapper">
      <Navbar />
      <div className="page-content-wrapper">
        <div className="page-content">
          {/* <!--start breadcrumb--> */}
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">Pages</div>
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0 align-items-center">
                  <li className="breadcrumb-item">
                    <a href="#">
                      <ion-icon name="home-outline"></ion-icon>
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Teams
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* <!--end breadcrumb--> */}

          {/* <div className="card radius-10">
            <div className="card-body">
              <h4>Dashobard</h4>                
              
            </div>
          </div> */}

          <div className="card">
            <div className="card-body">
              <button
                type="button"
                onClick={showModal}
                className="btn btn-sm btn-outline-success px-5 btnform"
              >
                <ion-icon
                  name="circle-plus-sharp"
                  role="img"
                  className="md hydrated"
                  aria-label="pricetags sharp"
                ></ion-icon>{" "}
                Teams
              </button>
              <div className="d-flex align-items-center">
                <h5 className="mb-0">Work Orders</h5>
                <form className="ms-auto position-relative">
                  <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                    <ion-icon name="search-sharp"></ion-icon>
                  </div>
                  <input
                    className="form-control ps-5"
                    type="text"
                    placeholder="search"
                  />
                </form>
              </div>
              <div className="table-responsive mt-3">
                <table className="table align-middle mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>OrderID</th>
                      <th>Building</th>
                      <th>Flat</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>WorkType</th>
                      <th>Responsible</th>
                      <th>Schedule&nbsp;DT</th>

                      <th>Status</th>
                      <th>Created</th>
                      {/* <th>WorkStatus</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {allteams.map((workorder, idx) => (
                      <tr>
                        <td>{workorder.id}</td>
                        <td>{workorder.building.name}</td>
                        <td>{workorder.tenant.flatno}</td>
                        <td>{workorder.title}</td>
                        <td>{workorder.description}</td>
                        <td>-</td>
                        <td>-</td>

                        <td>-</td>
                        <td>
                          <span className="badge bg-light-warning text-warning w-100">
                            Pending
                          </span>
                        </td>
                        <td>-</td>
                        {/* <td><div className="progress" style={{ "height": "5px;" }}>
                      <div className="progress-bar bg-success" role="progressbar"  style={{ "width": "100%" }}></div>
                      </div>
                    </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BackToTop />
      <div className="container-fluid">
        <Modal
          show={show}
          hideModal={hideModal}
          callback={saveWorkorder}
          title={"Create Request"}
        >
       
          <div className="col-12">
            <label className="form-label">Description</label>
            <input
              type="text"
              className="form-control"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
            />
          </div>
        </Modal>
      </div>

      <div className="overlay"></div>
    </div>
  );
};
