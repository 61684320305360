import React from 'react'

export const HeaderSecton = () => {
  return (
    <header className="top-header">
            <nav className="navbar navbar-expand gap-3">
              <div className="mobile-menu-button"><ion-icon name="menu-sharp"></ion-icon></div>
             <h2>Work Orders Management</h2>
             <div className="top-navbar-right ms-auto">

              <ul className="navbar-nav align-items-center">
                <li className="nav-item mobile-search-button">
                  <a className="nav-link" href="#">
                    <div className="">
                      <ion-icon name="search-sharp"></ion-icon>
                    </div>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link dark-mode-icon" href="#">
                    <div className="mode-icon">
                      <ion-icon name="moon-sharp"></ion-icon> 
                    </div>
                  </a>
                </li>
                {/* <li className="nav-item dropdown dropdown-large dropdown-apps">
                  <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" data-bs-toggle="dropdown">
                    <div className="">
                      <ion-icon name="apps-sharp"></ion-icon>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end dropdown-menu-dark">
                    <div className="row row-cols-3 g-3 p-3">
                      <div className="col text-center">
                        <div className="app-box mx-auto bg-gradient-purple text-white"><ion-icon name="cart-sharp"></ion-icon>
                        </div>
                        <div className="app-title">Orders</div>
                      </div>
                      <div className="col text-center">
                        <div className="app-box mx-auto bg-gradient-info text-white"><ion-icon name="people-sharp"></ion-icon>
                        </div>
                        <div className="app-title">Teams</div>
                      </div>
                      <div className="col text-center">
                        <div className="app-box mx-auto bg-gradient-success text-white"><ion-icon name="shield-checkmark-sharp"></ion-icon>
                        </div>
                        <div className="app-title">Tasks</div>
                      </div>
                      <div className="col text-center">
                        <div className="app-box mx-auto bg-gradient-danger text-white"><ion-icon name="videocam-sharp"></ion-icon>
                        </div>
                        <div className="app-title">Media</div>  
                      </div>
                      <div className="col text-center">
                        <div className="app-box mx-auto bg-gradient-warning text-white"><ion-icon name="file-tray-sharp"></ion-icon>
                        </div>
                        <div className="app-title">Files</div>
                      </div>
                      <div className="col text-center">
                        <div className="app-box mx-auto bg-gradient-branding text-white"><ion-icon name="notifications-sharp"></ion-icon>
                        </div>
                        <div className="app-title">Alerts</div>
                      </div>
                    </div>
                  </div>
                </li> */}
                <li className="nav-item dropdown dropdown-large">
                  <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" data-bs-toggle="dropdown">
                    <div className="position-relative">
                      <span className="notify-badge">8</span>
                      <ion-icon name="notifications-sharp"></ion-icon>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <a href="#">
                      <div className="msg-header">
                        <p className="msg-header-title">Notifications</p>
                        <p className="msg-header-clear ms-auto">Marks all as read</p>
                      </div>
                    </a>
                    <div className="header-notifications-list">
                      {/* <a className="dropdown-item" href="#">
                        <div className="d-flex align-items-center">
                          <div className="notify text-primary"><ion-icon name="cart-outline"></ion-icon>
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="msg-name">New Orders <span className="msg-time float-end">2 min
                          ago</span></h6>
                            <p className="msg-info">You have recived new orders</p>
                          </div>
                        </div>
                      </a>
                      <a className="dropdown-item" href="#">
                        <div className="d-flex align-items-center">
                          <div className="notify text-danger"><ion-icon name="person-outline"></ion-icon>
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="msg-name">New Customers<span className="msg-time float-end">14 Sec
                           ago</span></h6>
                            <p className="msg-info">5 new user registered</p>
                          </div>
                        </div>
                      </a> */}
                      {/* <a className="dropdown-item" href="#">
                        <div className="d-flex align-items-center">
                          <div className="notify text-success"><ion-icon name="document-outline"></ion-icon>
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="msg-name">24 PDF File<span className="msg-time float-end">19 min
                          ago</span></h6>
                            <p className="msg-info">The pdf files generated</p>
                          </div>
                        </div>
                      </a> */}
                      
                      {/* <a className="dropdown-item" href="#">
                        <div className="d-flex align-items-center">
                          <div className="notify text-info"><ion-icon name="checkmark-done-outline"></ion-icon>
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="msg-name">New Product Approved <span
                          className="msg-time float-end">2 hrs ago</span></h6>
                            <p className="msg-info">Your new product has approved</p>
                          </div>
                        </div>
                      </a>
                      <a className="dropdown-item" href="#">
                        <div className="d-flex align-items-center">
                          <div className="notify text-warning"><ion-icon name="send-outline"></ion-icon>
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="msg-name">Time Response <span className="msg-time float-end">28 min
                          ago</span></h6>
                            <p className="msg-info">5.1 min avarage time response</p>
                          </div>
                        </div>
                      </a>
                      <a className="dropdown-item" href="#">
                        <div className="d-flex align-items-center">
                          <div className="notify text-danger"><ion-icon name="chatbox-ellipses-outline"></ion-icon>
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="msg-name">New Comments <span className="msg-time float-end">4 hrs
                          ago</span></h6>
                            <p className="msg-info">New customer comments recived</p>
                          </div>
                        </div>
                      </a>
                      <a className="dropdown-item" href="#">
                        <div className="d-flex align-items-center">
                          <div className="notify text-primary"><ion-icon name="albums-outline"></ion-icon>
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="msg-name">New 24 authors<span className="msg-time float-end">1 day
                          ago</span></h6>
                            <p className="msg-info">24 new authors joined last week</p>
                          </div>
                        </div>
                      </a>
                      <a className="dropdown-item" href="#">
                        <div className="d-flex align-items-center">
                          <div className="notify text-success"><ion-icon name="shield-outline"></ion-icon>
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="msg-name">Your item is shipped <span className="msg-time float-end">5 hrs
                          ago</span></h6>
                            <p className="msg-info">Successfully shipped your item</p>
                          </div>
                        </div>
                      </a>
                      <a className="dropdown-item" href="#">
                        <div className="d-flex align-items-center">
                          <div className="notify text-warning"><ion-icon name="cafe-outline"></ion-icon>
                          </div>
                          <div className="flex-grow-1">
                            <h6 className="msg-name">Defense Alerts <span className="msg-time float-end">2 weeks
                          ago</span></h6>
                            <p className="msg-info">45% less alerts last 4 weeks</p>
                          </div>
                        </div>
                      </a> */}
                    </div>
                    <a href="#">
                      <div className="text-center msg-footer">View All Notifications</div>
                    </a>
                  </div>
                </li>
                <li className="nav-item dropdown dropdown-user-setting">
                  <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" href="#" data-bs-toggle="dropdown">
                    <div className="user-setting">
                      <img src="assets/images/avatars/06.png" className="user-img" alt=""/>
                    </div>
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                       <a className="dropdown-item" href="#">
                         <div className="d-flex flex-row align-items-center gap-2">
                            <img src="assets/images/avatars/06.png" alt="" className="rounded-circle" width="54" height="54"/>
                            <div className="">
                              <h6 className="mb-0 dropdown-user-name">Name</h6>
                              <small className="mb-0 dropdown-user-designation text-secondary">User</small>
                            </div>
                         </div>
                       </a>
                     </li>
                     <li><hr className="dropdown-divider"/></li>
                     {/* <li>
                        <a className="dropdown-item" href="pages-user-profile.html">
                           <div className="d-flex align-items-center">
                             <div className=""><ion-icon name="person-outline"></ion-icon></div>
                             <div className="ms-3"><span>Profile</span></div>
                           </div>
                         </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                           <div className="d-flex align-items-center">
                             <div className=""><ion-icon name="settings-outline"></ion-icon></div>
                             <div className="ms-3"><span>Setting</span></div>
                           </div>
                         </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="index2.html">
                           <div className="d-flex align-items-center">
                             <div className=""><ion-icon name="speedometer-outline"></ion-icon></div>
                             <div className="ms-3"><span>Dashboard</span></div>
                           </div>
                         </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                           <div className="d-flex align-items-center">
                             <div className=""><ion-icon name="wallet-outline"></ion-icon></div>
                             <div className="ms-3"><span>Earnings</span></div>
                           </div>
                         </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                           <div className="d-flex align-items-center">
                             <div className=""><ion-icon name="cloud-download-outline"></ion-icon></div>
                             <div className="ms-3"><span>Downloads</span></div>
                           </div>
                         </a>
                      </li> */}
                      {/* <li><hr className="dropdown-divider"/></li> */}
                      <li>
                        <a className="dropdown-item" href="/logout">
                           <div className="d-flex align-items-center">
                             <div className=""><ion-icon name="log-out-outline"></ion-icon></div>
                             <div className="ms-3"><span>Logout</span></div>
                           </div>
                         </a>
                      </li>
                  </ul>
                </li>

               </ul>

              </div>
            </nav>
          </header>
  )
}
