import React from "react";
import "./App.css";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import { Navbar } from "./components/Navbar";
import { BackToTop } from "./components/BackToTop";
import { Login } from "./components/Login";
import { Dashboard } from "./components/fms/Dashboard";
import { Myworkorders } from "./components/fms/Myworkorders";
import Logout from "./components/Logout";
import { Allworkorders } from "./components/fms/Allworkorders";
import { Tenants } from "./components/fms/Tenants";
import { Buildings } from "./components/Buildings";
import { Teams } from "./components/fms/Teams";
import { EmployeesManagement } from "./components/fms/EmployeesManagement";
import { AdminDashboard } from "./components/fms/admindashboard";
import { TenantUnits } from "./components/fms/TenantUnits";

function App() {
  const navigate = useNavigate;

  var userData = sessionStorage.getItem("userData");

  if (userData !== null) {
    //return navigate("/login");
    userData = JSON.parse(userData);
    var loginas = userData["loginas"];
  }

  return (
    <div className="wrapper">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />

        <Route
          path="/dashboard"
          element={
            <TenentElement>
              <Myworkorders />
            </TenentElement>
          }
        />
        <Route
          path="/myworkorders"
          element={
            <TenentElement>
              <Myworkorders />
            </TenentElement>
          }
        />

        <Route
          path="/admindashboard"
          element={
            <AdminElement>
              <AdminDashboard />
            </AdminElement>
          }
        />
        <Route
          path="/allworkorders"
          element={
            <AdminElement>
              <Allworkorders />
            </AdminElement>
          }
        />
        <Route
          path="/tenants"
          element={
            <AdminElement>
              <Tenants />
            </AdminElement>
          }
        />
        <Route
          path="/tenantunit"
          element={
            <AdminElement>
              <TenantUnits />
            </AdminElement>
          }
        />
        <Route
          path="/buildings"
          element={
            <AdminElement>
              <Buildings />
            </AdminElement>
          }
        />
        <Route
          path="/teams"
          element={
            <AdminElement>
              <Teams />
            </AdminElement>
          }
        />
        <Route
          path="/employees"
          element={
            <AdminElement>
              <EmployeesManagement />
            </AdminElement>
          }
        />

        <Route path="/logout" element={<Logout />} />
      </Routes>
    </div>
  );

  function TenentElement({ children }) {
    console.log(loginas);
    if (loginas === "TENANT") {
      return <>{children}</>;
    } else {
      return <Navigate to={"/"} />;
    }
  }

  function AdminElement({ children }) {
    if (loginas === "STAFF") {
      return <>{children}</>;
    } else {
      return <Navigate to={"/"} />;
    }
  }
}
export default App;
