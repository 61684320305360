import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import "./constants";
import axios from "axios";
import Swal from "sweetalert2";
import { Navbar } from "../Navbar";
import { BackToTop } from "../BackToTop";
import {
  EMPLOYEE_GET,
  EMPLOYEE_LISTALL,
  EMPLOYEE_SAVE,
  TEAM_LISTALL,
  TEAM_SAVE,
} from "./constants";
import { useNavigate } from "react-router-dom";
import $ from "jquery";

export const EmployeesManagement = () => {
  const navigate = useNavigate;

  const [show, setShow] = useState(false);
  const [allteams, setAllteams] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [teamid, setTeamid] = useState(0);

  const [userdata, setUserdata] = useState([]);
  const [employeeid, setEmployeeid] = useState(0);
  const [fileno, setFileno] = useState("");
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [empstatus, setEmpstatus] = useState(1);

  let loginUserData = "";

  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };

  const saveEmployee = () => {
    if (fullname.length < 3) {
      Swal.fire("Please enter minimum 3 characters for name");
      return false;
    }

    if (mobile.length !== 10) {
      Swal.fire("Please 10 digits mobile no. 05x1234567");
      return false;
    }

    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!re.test(email)) {
      Swal.fire("Please enter correct email");
      return false;
    }
    var postData = {
      id: employeeid,
      fullname: fullname,
      email: email,
      mobile: mobile,
      whatsapp: whatsapp,
      emp_status: empstatus,
      fileno: fileno,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };
    axios
      .post(EMPLOYEE_SAVE, postData, axiosConfig)
      .then((result) => {
        var res = result.data;
        if (res.status === false) {
          Swal.fire(res.msg);
          return false;
        }
        console.log("RESPONSE RECEIVED: ", res);
        Swal.fire(res.msg);

        hideModal();
        getAllEmployees();
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  const getAllEmployees = () => {
    var postData = {
      tenant: 1,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };
    axios
      .post(EMPLOYEE_LISTALL, postData, axiosConfig)
      .then((result) => {
        var res = result.data;

        setAllteams(res);
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  const emptyForm = () => {
    setEmployeeid(0);
    setFileno("");
    setFullname("");
    setMobile("");
    setWhatsapp("");
    setEmail("");
    setEmpstatus(1);
  };

  const getEmployee = (empid) => {
    emptyForm();
    setEmployeeid(empid);
    var postData = {
      id: empid,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };
    axios
      .post(EMPLOYEE_GET, postData, axiosConfig)
      .then((result) => {
        var res = result.data;

        setFileno(res.fileno);
        setFullname(res.fullname);
        setMobile(res.mobile);
        setWhatsapp(res.whatsapp);
        setEmail(res.email);
        setEmpstatus(res.emp_status);
        console.log(res);
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };
  useEffect(() => {
    loginUserData = JSON.parse(sessionStorage.getItem("userData"));
    if (!loginUserData) {
      navigate("/login");
    }

    // setUserdata(loginUserData);
    setTimeout(getAllEmployees(), 1500);
  }, []);

  const onInputChange = (e) => {
    const { value } = e.target;
    console.log("Input value: ", value);

    if (value.length > 5) {
      return false;
    }

    const re = /^[A-Za-z0-9]+$/;
    if (value === "" || re.test(value)) {
      setFileno(value);
    }
  };

  return (
    <div className="wrapper">
      <Navbar />
      <div className="page-content-wrapper">
        <div className="page-content">
          {/* <!--start breadcrumb--> */}
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0 align-items-center">
                  <li className="breadcrumb-item active" aria-current="page">
                    <h2>Employees Management</h2>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* <!--end breadcrumb--> */}

          {/* <div className="card radius-10">
            <div className="card-body">
              <h4>Dashobard</h4>                
              
            </div>
          </div> */}

          <div className="card">
            <div className="card-body">
              <button
                type="button"
                onClick={(e) => {
                  showModal();
                  emptyForm();
                  setEmployeeid(0);
                }}
                className="btn btn-xs btn-success px-5 btnform"
              >
                <ion-icon
                  name="circle-plus-sharp"
                  role="img"
                  className="md hydrated"
                  aria-label="pricetags sharp"
                ></ion-icon>
                Add Employee
              </button>
              {/* <div className="d-flex align-items-center">
                <h5 className="mb-0">Employees Management</h5>
                <form className="ms-auto position-relative">
                  <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                    <ion-icon name="search-sharp"></ion-icon>
                  </div>
                  <input
                    className="form-control ps-5"
                    type="text"
                    placeholder="search"
                  />
                </form>
              </div> */}
              <div className="table-responsive mt-3">
                <table className="table align-middle mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>ID</th>
                      <th>Fileno</th>
                      <th>Full Name</th>
                      <th>Mobile</th>
                      <th>WhatsApp</th>
                      <th>Email</th>
                      <th>Status</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allteams.map((emp, idx) => (
                      <tr>
                        <td>{emp.id}</td>
                        <td>{emp.fileno}</td>
                        <td>{emp.fullname}</td>
                        <td>{emp.mobile}</td>
                        <td>{emp.whatsapp}</td>
                        <td>{emp.email}</td>
                        <td>{emp.emp_status ? "Active" : "Disable"}</td>

                        <td>
                          <div class="btn-group">
                            <button
                              type="button"
                              class="btn btn-outline-primary"
                            >
                              Action
                            </button>
                            <button
                              type="button"
                              class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              {" "}
                              <span class="visually-hidden">
                                Toggle Dropdown
                              </span>
                            </button>
                            <ul class="dropdown-menu">
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  onClick={(e) => {
                                    getEmployee(emp.id);
                                    showModal();
                                  }}
                                >
                                  Update
                                </a>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <BackToTop />
      <div className="container-fluid">
        <Modal
          show={show}
          hideModal={hideModal}
          callback={saveEmployee}
          title={"Employee form"}
        >
          <div className="col-12">
            <label className="form-label">Fileno</label>
            <input
              type="text"
              className="form-control"
              value={fileno}
              onChange={onInputChange}
            />
          </div>
          <div className="col-12">
            <label className="form-label">Fullname</label>
            <input
              type="text"
              className="form-control"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
            />
          </div>
          <div className="col-12">
            <label className="form-label">Mobile</label>
            <input
              type="text"
              className="form-control"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>
          <div className="col-12">
            <label className="form-label">whatsapp</label>
            <input
              type="text"
              className="form-control"
              value={whatsapp}
              onChange={(e) => setWhatsapp(e.target.value)}
            />
          </div>
          <div className="col-12">
            <label className="form-label">Email</label>
            <input
              type="text"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="col-12">
            <label className="form-label">Status</label>
            <select
              className="form-select mb-3 single-select"
              aria-label="Default select example"
              value={empstatus}
              onChange={(e) => setEmpstatus(e.target.value)}
            >
              <option value={1}>Active</option>
              <option value={0}>Disable</option>
            </select>
          </div>
        </Modal>
      </div>

      <div className="overlay"></div>
    </div>
  );
};
