export const BASE_LIVE_URL = "http://apps.webhop.biz:2087/test/api/";
// export const BASE_LOCAL_URL = "https://fms.marfaa.ae/apis/public/api/";
export const BASE_LOCAL_URL = "https://fm.almarwanrealestate.com/apis/public/api/";
// export const BASE_LOCAL_URL = "http://127.0.0.1:8000/api/";
export const BASE_TEST_URL = "http://apps.webhop.biz:2087/test/api/";

export const DEFAULT_COUNTRY = 232;

export const LOGIN_URL = BASE_LOCAL_URL + "login";
export const ALL_WORKORDER = BASE_LOCAL_URL + "Workorders/allworkorders";
export const MY_WORKORDER = BASE_LOCAL_URL + "Workorders/myworkorders";
export const SAVE_WORKORDER = BASE_LOCAL_URL + "Workorders/save";
export const SCHEDULE_WORKORDER = BASE_LOCAL_URL + "Workorders/updateSchedule";
export const WORKORDER_STATUS = BASE_LOCAL_URL + "Workorders/updateStatus";

export const COUNTRY_LIST = BASE_LOCAL_URL + "countrylist";
export const CITIES_LIST = BASE_LOCAL_URL + "countrycities";

export const BUILDING_LIST = BASE_LOCAL_URL + "buildinglist";
export const BUILDING_GET = BASE_LOCAL_URL + "getbuilding";
export const BUILDING_UNITS = BASE_LOCAL_URL + "buildingunitlist";
export const BUILDING_SAVE = BASE_LOCAL_URL + "savebuilding";

export const BUILDING_UNITS_SAVE = BASE_LOCAL_URL + "savebuildingunit";


export const TENANT_LISTALL = BASE_LOCAL_URL + "Tenants/all";
export const TENANT_INFO = BASE_LOCAL_URL + "Tenants/get";
export const TENANT_create = BASE_LOCAL_URL + "Tenants/create";
export const TENANT_SAVE = BASE_LOCAL_URL + "Tenants/save";
export const TENANT_ACCOUNTINFO = BASE_LOCAL_URL + "Tenants/accinfo";
export const TENANT_UNITS = BASE_LOCAL_URL + "Tenants/tenantUnit";
export const TENANT_UNIT_SAVE = BASE_LOCAL_URL + "Tenants/saveTenantUnit";


export const TEAM_LISTALL = BASE_LOCAL_URL + "Employees/allteams";
export const TEAM_GET = BASE_LOCAL_URL + "Employees/getteam";
export const TEAM_SAVE = BASE_LOCAL_URL + "Employees/saveteam";

export const EMPLOYEE_LISTALL = BASE_LOCAL_URL + "Employees/all";
export const EMPLOYEE_GET = BASE_LOCAL_URL + "Employees/get";
export const EMPLOYEE_SAVE = BASE_LOCAL_URL + "Employees/save";
export const EMPLOYEE_DropDown = BASE_LOCAL_URL + "Employees/getlist";
export const EMPLOYEE_DropDownSelected =  BASE_LOCAL_URL + "Employees/getlistselected";
