import React, { useEffect, useState } from 'react'
import Modal from './Modal';
import { BUILDING_UNITS, BUILDING_UNITS_SAVE } from './fms/constants';
import axios from 'axios';
import Swal from 'sweetalert2'

export const BuildingUnits = ({building}) => {
    const [unitName, setUnitName] = useState("")
    const [show, setShow] = useState(false);
    const [allunits, setAllunits] = useState([]);
    const [buildingUnitId, setBuildingUnitId] = useState(0)
    const [buildingId, setBuildingId] = useState(building.building_id)

    
    const showModal = () => {
        setShow(true);
      };
      const hideModal = () => {
        setShow(false);
      };
      const getBuildingUnits = () => {

        var postData = {
            building: building.building_id,        
          };
          let axiosConfig = {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          };
          axios
            .post(BUILDING_UNITS, postData, axiosConfig)
            .then((result) => {
            var res = result.data;
            
            if (res.status === false) {
                Swal.fire(res.msg);    
                return false;
              }
            setAllunits(res)
             
            })
            .catch((err) => {
              console.log("AXIOS ERROR: ", err);
            });
        
      }

      const saveUnit = () => {
        var postData = {
            building_id: building.building_id,        
            unitname: unitName
          };
          let axiosConfig = {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          };
          axios
            .post(BUILDING_UNITS_SAVE, postData, axiosConfig)
            .then((result) => {
            var res = result.data;
            Swal.fire(res.msg);
            if (res.status === false) {                
                return false;
              }
            getBuildingUnits()
            setUnitName("");
             
            })
            .catch((err) => {
              console.log("AXIOS ERROR: ", err);
            });
        
      }

      useEffect(() => {
        // setAllunits([])
        console.log("called", building.building_id)
        getBuildingUnits();
      }, []);
 
      /**
       * A patch to controll the request
       */
      if (buildingId !== building.building_id) { 
        getBuildingUnits(); setBuildingId(building.building_id)
      }
        
      
  return (
    <>
        <button
                  type="button"
                  onClick={showModal}
                  className="btn btn-sm btn-success px-5 btnform"
                >
                  <ion-icon
                    name="circle-plus-sharp"
                    role="img"
                    className="md hydrated"
                    aria-label="pricetags sharp"
                  ></ion-icon>{" "}
                  Add New Unit
                </button>
                <hr />
               
                
                  <table className="table align-middle mb-0 table-bordered">
                    <thead className="table-light">
                      <tr>
                        <th>ID</th>
                        
                        <th>Unit</th>
                        <th>Building</th>
                        <th>Action</th>                        
                      </tr>
                    </thead>
                    <tbody>
                    { allunits.length === 0 ? (
                        <tr>
                            <td colSpan={8} className='text-center' ><h5>No Units found</h5></td>
                        </tr>
                    ) : (                    
                    allunits.map((unit, idx) => (
                      <tr>
                        <td>{unit.id}</td>
                        <td>{unit.name}</td>
                        <td>{unit.building.name}</td>
                        <td>
                        <div class="btn-group">
                        <button type="button" class="btn btn-outline-primary">Action</button>
                        <button type="button" class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">	<span class="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul class="dropdown-menu">
                          <li><a class="dropdown-item" href="#" onClick={(e) => {setBuildingUnitId(unit.id); showModal()}}>Update</a>
                          </li>
                          
                          {/* <li>
                            <hr class="dropdown-divider" />
                          </li> */}
                          {/* <li><a class="dropdown-item" href="#" onClick={(e) => {setBuildingUnitId(unit.id); showModal()}}>Manage Units</a></li> */}
                        </ul>
                      </div>
                        
                        </td>                        
                      </tr>
                        )))}
                      
                    </tbody>
                  </table>
                

        
        <div className="container-fluid">        
          <Modal show={show} hideModal={hideModal} callback={saveUnit} title={"Unit Form"}>
            
              
              <div className="col-12">
                <label className="form-label">Unit Name</label>
                <input type="text" className="form-control"
                value={unitName}
                onChange={(e) => setUnitName(e.target.value)}
                />
              </div>
            
          </Modal>
        </div>
        
      
    </>
)}
