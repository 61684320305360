import React, { useEffect, useState } from "react";
import { Navbar } from "../Navbar";
import { BackToTop } from "../BackToTop";
import Modal from "../Modal";
import ModalLauncher from "../ModalLauncher";
import "./modal.css";
import $ from "jquery";
import {
  COUNTRY_LIST,
  TENANT_LISTALL,
  TENANT_SAVE,
  TENANT_INFO,
  BUILDING_LIST,
  BUILDING_UNITS,
  TENANT_ACCOUNTINFO,
} from "./constants";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

import DataTable from "react-data-table-component";
import { ThreeDots } from "react-loader-spinner";
import ModalBig from "../ModalBig";

export const Tenants = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [unitModel, setUnitModel] = useState(false)
  const [allTenants, setAllTenants] = useState([]);
  const [loading, setLoading] = useState(false)
  const [building, setBuilding] = useState("");
  const [flatno, setFlatno] = useState("");
  const [buildingunits, setBuildingunits] = useState([]);
  const [tenantId, setTenantId] = useState(0);
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [countryId, setCountryId] = useState("");
  const [profession, setProfession] = useState("");

  const [countrylist, setCountrylist] = useState([]);
  const [buildinglist, setBuildinglist] = useState([]);

  const [columns, setColumns] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("");
  const [showUnitModal, setShowUnitModal] = useState(false);

  const [loadUnits, setLoadUnits] = useState(null)

  var cols = [
    {
      name: "id",
      selector: (row) => row.id,
    },
    {
      name: "Building",
      selector: (row) => row.building.name,
      sortable: true,
    },
    {
      name: "Flat",
      selector: (row) => row.flatno,
    },
    {
      name: "Name",
      selector: (row) => row.fullname,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
    },
    {
      name: "whatsapp",
      selector: (row) => row.whatsapp,
    },
    {
      name: "Professon",
      selector: (row) => row.professon,
    },
    // {
    //   name: 'Country',
    //   selector: row => row.country.country_enName,
    // }
  ];

  let loginUserData = "";
  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };

  const hideUnitModal = () => {
    setUnitModel(false)
  }

  const saveUnitTenant = () => {
    //
  }

  const assignUnits = () => {
    // 
  }

  const showUnitsModal = () => {
    setShowUnitModal(true);
  };
  const hideUnitsModal = () => {
    setShowUnitModal(false);
  };
  const saveTenant = () => {
    if (building === "") {
      Swal.fire("Choose building");
      return false;
    }
    if (flatno === "") {
      Swal.fire("Enter flatno");
      return false;
    }
    if (fullname.trim() === "") {
      Swal.fire("Enter full name");
      return false;
    }
    if (email.trim() === "") {
      Swal.fire("Enter email");
      return false;
    }
    if (password.trim().length < 6) {
      Swal.fire("Enter password minimum 6 characters");
      return false;
    }
    if (mobile.trim().length !== 12) {
      Swal.fire("Enter mobile no: 9715x1234567");
      return false;
    }

    if (countryId === "") {
      Swal.fire("Choose country");
      return false;
    }

    var postData = {
      tenant_id: tenantId,
      fullname: fullname,
      email: email,
      pasword: password,
      mobile: mobile,
      whatsapp: whatsapp,
      countryId: countryId,
      profession: profession,
      building: building,
      flatno: flatno,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };
    axios
      .post(TENANT_SAVE, postData, axiosConfig)
      .then((result) => {
        var res = result.data;
        console.log("RESPONSE RECEIVED: ", res);
        if (res.status === false) {
          var errors = res['errors'];
          var res_msg= "";
          $.each(errors , function(index, value) {
            res_msg += value[0]+"\n";
             });
             
          Swal.fire({
            title: "Error",
            icon: "error",
            text: res_msg
          });
          return false;
        }
        console.log("RESPONSE RECEIVED: ", res);
        Swal.fire(res.msg);
        // setWoTitle("");
        // setWoDescription("");

        hideModal();
        getAllTenants();
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  const getAllTenants = () => {
    setLoading(true)
    var postData = {
      tenant: 1,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };
    axios
      .post(TENANT_LISTALL, postData, axiosConfig)
      .then((result) => {
        var res = result.data;

        setAllTenants(res);
        setLoading(false)
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  const getTenantInfo = (tid) => {
    setTenantId("");
    setBuilding("");
    setFlatno("");
    setFullname("");
    setEmail("");
    setPassword("");
    setMobile("");
    setWhatsapp("");
    setCountryId("");
    setProfession("");
    setTenantId(tid);
    
    console.log("tid", tid);
    var postData = {
      tenant: tid,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };
    axios
      .post(TENANT_INFO, postData, axiosConfig)
      .then((result) => {
        var res = result.data;
        setTenantId(res.id);
        setBuilding(res.building_id);
        setFlatno(res.flatno);
        setFullname(res.fullname);
        setEmail(res.email);
        setPassword(res.pasword);
        setMobile(res.mobile);
        setWhatsapp(res.whatsapp);
        setCountryId(res.country_id);
        setProfession(res.profession);
        
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  const getCountryList = () => {
    var postData = {
      tenant: 1,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };
    axios
      .post(COUNTRY_LIST, postData, axiosConfig)
      .then((result) => {
        var res = result.data;

        setCountrylist(res);
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };
  const getBuildings = () => {
    var postData = {
      tenant: 1,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };
    axios
      .post(BUILDING_LIST, postData, axiosConfig)
      .then((result) => {
        var res = result.data;

        setBuildinglist(res);
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  const getBuildingUnits = () => {
    var postData = {
      building: building,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };
    axios
      .post(BUILDING_UNITS, postData, axiosConfig)
      .then((result) => {
        var res = result.data;

        setBuildingunits(res);
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  const handleBuilding = (e) => {
    setBuilding(e.target.value);
  };

  const loadUnitsComponent = async (tenant) => {
    // const module = import('./TenantUnits');
    // const {TenantUnits} = await module;
    // sessionStorage.setItem('tenantRef', tenant)
    // setLoadUnits(null)
    // console.log('bid', tenant);
    // setLoadUnits(<TenantUnits useEffect  tenant={{ tenant: tenant, timer: Math.random() }}  />);
  
    navigate("/tenantunit?tenant="+tenant);

  }

  const sendAccountInfo = (tenant_id) => {
    Swal.fire({
      title: "Do you want to sent details?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Send",
      //denyButtonText: `Don't save`
    }).then((result) => {
      if (result.isConfirmed) {
        var postData = {
          tenant_id: tenant_id,
        };
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        };
        axios
          .post(TENANT_ACCOUNTINFO, postData, axiosConfig)
          .then((result) => {
            var res = result.data;

            Swal.fire({
              position: "top-end",
              icon: "success",
              title: res.msg,
              showConfirmButton: false,
              timer: 1500,
            });
            // Swal.fire(res.msg);
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
          });
      }
    });
  };
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleColumnChange = (e) => {
    setSelectedColumn(e.target.value);
  };

  // const filteredData  = allTenants.filter(item =>
  //   Object.values(item).some(value =>
  //     // value.toString().toLowerCase().includes(searchTerm.toLowerCase())
  //     value !== null && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
  //   )
  // );

  const filteredData = allTenants.filter((item) => {
    const filtered = Object.values(item).some(
      (value) =>
        value !== null &&
        value
          .toString()
          .toLowerCase()
          .split(" ")
          .some((word) => {
            // console.log("Search Term:", searchTerm.trim().toLowerCase());
            // console.log("Value:", value.toString().trim().toLowerCase());
            //console.log("Word:", word);
            // return word.includes(searchTerm.trim().toLowerCase())
            return word.indexOf(searchTerm.trim().toLowerCase()) !== -1;
          })
    );
    // console.log("Filtered:", filtered);
    return filtered;
  });
  useEffect(() => {
    loginUserData = JSON.parse(sessionStorage.getItem("userData"));
    if (!loginUserData) {
      navigate("/login");
    }
    console.log(loginUserData);
    setTimeout(getAllTenants(), 1500);
    setTimeout(getCountryList(), 2000);
    setTimeout(getBuildings(), 2000);

    setColumns(cols);
  }, []);

  const customStyles = {
    headRow: {
      style: {
        background: "#000000",
        color: "#fff",
      },
    },
  };

  $(".btnform").on("click", function (event) {
    $("#frm-workorder").trigger("reset");
  });

  return (
    <>
      <div className="wrapper">
        <Navbar />
        <div className="page-content-wrapper">
          <div className="page-content">
            {/* <!--start breadcrumb--> */}
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
              <div className="breadcrumb-title pe-3">Pages</div>
              <div className="ps-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0 p-0 align-items-center">
                    <li className="breadcrumb-item">
                      <a href="#">
                        <ion-icon name="home-outline"></ion-icon>
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Tenants
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            {/* <!--end breadcrumb--> */}

            {/* <div className="card radius-10">
            <div className="card-body">
              <h4>Dashobard</h4>                
              
            </div>
          </div> */}

            <div className="card">
              <div className="card-body">
                <button
                  type="button"
                  onClick={showModal}
                  className="btn btn-sm btn-outline-success px-5 btnform"
                >
                  <ion-icon name="person-sharp"></ion-icon>
                  Add new Tenant
                </button>
                <div className="d-flex align-items-center">
                  <div className="ms-auto position-relative">
                    <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                      <ion-icon name="search-sharp"></ion-icon>
                    </div>
                    <input
                      className="form-control ps-5"
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </div>
                 
                </div>
                <hr />

                <div className="table-responsive mt-3">
                  

                  <table className="table align-middle mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>ID</th>
                        {/* <th>Building</th> */}
                        {/* <th>Flat</th> */}
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>whatsapp</th>
                        {/* <th>Professon</th> */}
                        {/* <th>Country</th> */}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                      <ThreeDots
                      visible={true}
                      height="80"
                      width="80"
                      color="#4fa94d"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      /> ) :  
                      allTenants.map((tenant, idx) => (
                        <tr>
                          <td>{tenant.id}</td>
                          {/* <td>{tenant.building?.name}</td> */}
                          {/* <td>{tenant.flatno}</td> */}
                          <td>{tenant.fullname}</td>
                          <td>{tenant.email}</td>
                          <td>{tenant.mobile}</td>
                          <td>{tenant.whatsapp}</td>
                          {/* <td>{tenant.profession}</td> */}
                          {/* <td>{tenant.country.country_enName}</td> */}
                          <td>
                            <div class="btn-group">
                              <button
                                type="button"
                                class="btn btn-outline-primary"
                              >
                                Action
                              </button>
                              <button
                                type="button"
                                class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {" "}
                                <span class="visually-hidden">
                                  Toggle Dropdown
                                </span>
                              </button>
                              <ul class="dropdown-menu">
                                <li>
                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    onClick={(e) => {
                                      getTenantInfo(tenant.id);
                                      showModal();
                                    }}
                                  >
                                    Profile
                                  </a>
                                </li>
                                <li>
                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    onClick={(e) => {
                                      sendAccountInfo(tenant.id);
                                    }}
                                  >
                                    Send Login Info
                                  </a>
                                </li>
                                <li>
                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    onClick={(e) => {
                                      assignUnits(tenant.id);
                                      showUnitsModal();
                                      loadUnitsComponent(tenant.id) 
                                    }}
                                  >
                                    Assign Units
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div style={{ height: "150px" }}>&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BackToTop />
        <div className="container-fluid">

        <ModalBig show={showUnitModal} hideModal={hideUnitsModal} callback={""} title={"Tenant Units"}>
            {loadUnits}
                        {/* <BuildingUnits building={{ building_id: buildingId }}  /> */}
            </ModalBig>
        <Modal
            show={show}
            hideModal={hideUnitModal}
            callback={saveUnitTenant}
            title={"Tenant Units"}
          >
            <div className="col-12">
                <label className="form-label">Building</label>
                <select
                  className="form-select mb-3 single-select"
                  aria-label="Default select example"
                  value={building}
                  onChange={handleBuilding}
                >
                  <option value={""}>Select Building</option>
                  {buildinglist.map((option, idx) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12  mb-3">
                <label className="form-label">FlatNo.</label>
                <input
                  type="text"
                  className="form-control"
                  value={flatno}
                  placeholder="Enter Flat no"
                  onChange={(e) => setFlatno(e.target.value)}
                />
              </div>
            </Modal>
          <Modal
            show={show}
            hideModal={hideModal}
            callback={saveTenant}
            title={"Create Request"}
          >
            <form
              onSubmit={() => {
                return false;
              }}
            >
              
              <div className="col-12  mb-3">
                <label className="form-label">Full Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={fullname}
                  placeholder="Enter Tenant name"
                  onChange={(e) => setFullname(e.target.value)}
                />
              </div>
              <div className="col-12">
                <label className="form-label">Email(username)</label>
                <input
                  type="text"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="col-12">
                <label className="form-label">Password</label>
                <input
                  type="password"
                  className="form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="col-12">
                <label className="form-label">Mobile</label>
                <input
                  type="text"
                  className="form-control"
                  value={mobile}
                  placeholder="0501234567"
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>
              <div className="col-12">
                <label className="form-label">WhatsApp</label>
                <input
                  type="text"
                  className="form-control"
                  value={whatsapp}
                  onChange={(e) => setWhatsapp(e.target.value)}
                />
              </div>
              <div className="col-12">
                <label className="form-label">Country</label>
                <select
                  className="form-select mb-3 single-select"
                  aria-label="Default select example"
                  value={countryId}
                  onChange={(e) => setCountryId(e.target.value)}
                >
                  {countrylist.map((option, idx) => (
                    <option key={option.id} value={option.id}>
                      {option.country_enName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12">
                <label className="form-label">Profession</label>
                <input
                  type="text"
                  className="form-control"
                  value={profession}
                  onChange={(e) => setProfession(e.target.value)}
                />
              </div>
            </form>
          </Modal>
        </div>

        <div className="overlay"></div>
      </div>
    </>
  );
};
