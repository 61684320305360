import React, { useEffect, useState } from "react";
import { Navbar } from "../Navbar";
import { BackToTop } from "../BackToTop";
import Modal from "../Modal";
import ModalLauncher from "../ModalLauncher";
import "./modal.css";
import $, { valHooks } from "jquery";
import { ALL_WORKORDER, MY_WORKORDER, SAVE_WORKORDER } from "./constants";
import axios from "axios"; 
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

export const Myworkorders = () => {
  const navigate = useNavigate();
  const [tenantId, setTenantId] = useState(0);
  const [show, setShow] = useState(false);
  const [woTitle, setWoTitle] = useState("");
  const [woDescription, setWoDescription] = useState("");
  const [allworkorders, setAllworkorders] = useState([]);
  const [blnAddButton, setBlnAddButton] = useState(false)
  const [userdata, setUserdata] = useState([]);
  const [dropdownUnits, setDropdownUnits] = useState([])
  const [tenantUnitId, setTenantUnitId] = useState(0)
  const [loading, setLoading] = useState(false)

  let loginUserData = "";
  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };

  const saveWorkorder = () => {
    if (woTitle === "") {
      Swal.fire("Please select title from the list");
      return false;
    }

    if (woDescription.trim().length < 3) {
      Swal.fire("Please enter atleast in 3 charaters");
      return false;
    }

    var postData = {
      tenant_unit_id: tenantUnitId,
      tenant_id: tenantId,
      woTitle: woTitle,
      woDescription: woDescription,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };
    axios
      .post(SAVE_WORKORDER, postData, axiosConfig)
      .then((result) => {
        var res = result.data;
        if (res.status === false) {
          Swal.fire(res.msg);
          return false;
        }
        // console.log("RESPONSE RECEIVED: ", res);
        Swal.fire(res.msg);
        setWoTitle("");
        setWoDescription("");

        hideModal();
        getAllWorkOrders(tenantId);
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  const getAllWorkOrders = (tid) => {
    setLoading(true);
    var postData = {
      tenant: tid,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };
    axios
      .post(MY_WORKORDER, postData, axiosConfig)
      .then((result) => {
        var res = result.data;

        setAllworkorders(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  const handleWorkorderUnit = (e) => {
    //handleWorkorderUnit
    setTenantUnitId(e.target.value);

  }

  useEffect(() => {

    loginUserData = JSON.parse(sessionStorage.getItem("userData"));
    if (!loginUserData) {
      navigate("/login");
    }
    var loginUser = loginUserData["content"];
    var tid = loginUserData["content"]["id"];
    setTenantId(tid)
    if (loginUser['units'].length) {
      setBlnAddButton(true)
      let units=[];
      $.each(loginUser['units'], function (index, value) {
        
        value["unit"]['show_name']= value['unit']["name"]+" ("+value['building'].name+")";
        value["unit"]['tenant_unit_id']= value['id'];
        value["unit"]['unit_id']= value['id'];
        units[index]= value['unit'];
    });
    console.log("v", units);
    setDropdownUnits(units);

    }
    
    setTenantId(tid);
    
    setUserdata(loginUserData);


    getAllWorkOrders(tid)
  }, []);

  $(".btnform").on("click", function (event) {
    $("#frm-workorder").trigger("reset");
  });

  return (
    <>
      <div className="wrapper">
        <Navbar />
        <div className="page-content-wrapper">
          <div className="page-content">
            {/* <!--start breadcrumb--> */}
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
              <div className="breadcrumb-title pe-3">FMS</div>
              <div className="ps-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0 p-0 align-items-center">
                    
                    <li className="breadcrumb-item active" aria-current="page">
                      My WorkOrders
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            {/* <!--end breadcrumb--> */}

            {/* <div className="card radius-10">
            <div className="card-body">
              <h4>Dashobard</h4>                
              
            </div>
          </div> */}

            <div className="card">
              <div className="card-body">
                <div className="text-right" style={{ textAlign: "right" }}>
                { blnAddButton ? (
                <button
                  type="button"
                  onClick={showModal}
                  className="btn btn-sm btn-success px-5 btnform"
                >
                  <ion-icon
                    name="add-circle"
                    role="img"
                    className="md hydrated"
                    aria-label="pricetags sharp"
                  ></ion-icon>
                  Request Work
                </button>
                ) : "" }
                </div>
                
                <div className="table-responsive mt-3">
                  <table className="table align-middle mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>ID</th>                       
                        
                        <th>Title</th>
                        <th>Description</th>
                        <th>WorkType</th>
                        <th>Responsible</th>
                        <th>Schedule&nbsp;DT</th>

                        <th>Status</th>
                        <th>Created</th>
                        {/* <th>WorkStatus</th> */}
                      </tr>
                    </thead>
                    <tbody>
                    {loading ? (
                      <ThreeDots
                      visible={true}
                      height="80"
                      width="80"
                      color="#4fa94d"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      /> ) : 
                      allworkorders.map((workorder, idx) => (
                        <tr>
                          <td>{workorder.id}</td>
                          <td>{workorder.title}</td>
                          <td>{workorder.description}</td>
                          <td>{workorder.work_type}</td>
                          <td>-</td>
                          <td>{workorder.inspection_dt}</td>
                          
                          <td>
                            {workorder.work_status}
                          </td>
                          <td>{(workorder.created_at).substring(0, 10)}</td>
                          {/* <td><div className="progress" style={{ "height": "5px;" }}>
                      <div className="progress-bar bg-success" role="progressbar"  style={{ "width": "100%" }}></div>
                      </div>
                    </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BackToTop />
        <div className="container-fluid">
          <Modal
            show={show}
            hideModal={hideModal}
            callback={saveWorkorder}
            title={"Create Request"}
          >
             <label className="form-label">Select Unit</label>
            <select
                  className="form-select mb-3 single-select"
                  aria-label="Default select example"
                  value={tenantUnitId}
                  onChange={handleWorkorderUnit}
                >
                  <option value={""}>Select Unit</option>
                  {dropdownUnits.map((option, idx) => (
                    <option key={option.tenant_unit_id} value={option.tenant_unit_id}>
                      {option.show_name}
                      {/* {JSON.stringify(option)} */}
                    </option>
                  ))}
                </select>
            <div className="col-12">
              <label className="form-label">WorkOrder Title</label>
              <select
                className="form-select mb-3"
                aria-label="Default select example"
                value={woTitle}
                onChange={(e) => setWoTitle(e.target.value)}
              >
                <option value={""}>Select from List</option>
                <optgroup label="MEP Part">
                  <option
                    defaultValue="Water leaking from the ceiling"
                    value={"Water leaking from the ceiling"}
                  >
                    Water leaking from the ceiling{" "}
                  </option>
                  <option value="Basin water leaking">
                    Basin water leaking
                  </option>
                  <option value="Water heater">Water heater</option>
                  <option value="Shattaf">Shattaf</option>
                  <option value="Shower mixer">Shower mixer</option>
                  <option value="Basin mixer">Basin mixer</option>
                  <option value="Sink mixer">Sink mixer</option>
                  <option value="Drainage block">Drainage block</option>
                  <option value="WC cover">WC cover</option>
                  <option value="Satellite">Satellite</option>
                  <option value="Enterphone">Enterphone</option>
                </optgroup>
                <optgroup label="FIT OUT Part">
                  <option value={"Painting - Humidity"}>
                    {"Painting - Humidity"}
                  </option>
                  <option value={"Bubbles on the wall"}>
                    {"Bubbles on the wall"}
                  </option>
                  <option value={"Door machine"}>{"Door machine"}</option>
                  <option value={"Door cylinder"}>{"Door cylinder"}</option>
                  <option value={"Door polishing"}>{"Door polishing"}</option>
                  <option value={"Kitchen cabinet"}>{"Kitchen cabinet"}</option>
                  <option value={"Kitchen doors"}>{"Kitchen doors"}</option>
                  <option value={"Washroom grouting"}>
                    {"Washroom grouting"}
                  </option>
                  <option value={"Kitchen grouting"}>
                    {"Kitchen grouting"}
                  </option>
                  <option value={"Aluminum handle"}>{"Aluminum handle"}</option>
                </optgroup>
                <optgroup label="A / C Part">
                  <option value={"Thermostat"}>{"Thermostat"}</option>
                  <option value={"Not cooling"}>{"Not cooling"}</option>
                  <option value={"Noisy"}>{"Noisy"}</option>
                  <option value={"Water leaking"}>{"Water leaking"}</option>
                  <option value={"Drainage and bad smell"}>
                    {"Drainage and bad smell"}
                  </option>
                  <option value={"Cleaning the diffuser"}>
                    {"Cleaning the diffuser"}
                  </option>
                </optgroup>
                <optgroup label="Security Part">
                  <option value={"High noises"}>{"High noises"}</option>
                  <option value={"Bad smell"}>{"Bad smell"}</option>
                  <option value={"Main Building door"}>
                    {"Main Building door"}
                  </option>
                  <option value={"Enterphone"}>{"Enterphone"}</option>
                  <option value={"Garbage issue"}>{"Garbage issue"}</option>
                  <option value={"Parking issue"}>{"Parking issue"}</option>
                  <option value={"Delivery team"}>{"Delivery team"}</option>
                </optgroup>
                <optgroup label="Common area ">
                  <option value={"Elevator"}>{"Elevator"}</option>
                  <option value={"Lighting "}>{"Lighting "}</option>
                  <option value={"Water leaking"}>{"Water leaking"}</option>
                  <option value={"Doors"}>{"Doors"}</option>
                  <option value={"Garbage issue"}>{"Garbage issue"}</option>
                </optgroup>
                <optgroup label="Other Part  ">
                  <option value={"All other complaints"}>
                    {"All other complaints"}
                  </option>
                </optgroup>
              </select>
            </div>
            <div className="col-12">
              <label className="form-label">Description</label>
              <input
                type="text"
                className="form-control"
                value={woDescription}
                onChange={(e) => setWoDescription(e.target.value)}
              />
            </div>
          </Modal>
        </div>
        <div className="overlay"></div>
      </div>
    </>
  );
};
