import React, { useState } from "react";
import { LOGIN_URL } from "./fms/constants";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginType, setLoginType] = useState("");

  const handleLogin = () => {
    if (username.trim().length < 3) {
      Swal.fire("Please enter correct username");
      return false;
    }
    if (password.trim().length < 3) {
      Swal.fire("Please enter minimum 3 characters");
      return false;
    }
    if (loginType.trim() == "") {
      Swal.fire("Please choose login type.");
      return false;
    }
    var postData = {
      username: username,
      password: password,
      loginas: loginType,
    };

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };

    axios
      .post(LOGIN_URL, postData, axiosConfig)
      .then((result) => {
        var res = result.data;
        if (res.status === false) {
          Swal.fire({
            title: "Login Failed",
            html: res.msg,
            icon: "error",
          });
          return false;
        } else {
          console.log("RESPONSE RECEIVED: ", res);
          sessionStorage.setItem("userData", JSON.stringify(res));
          window.location.href =
            loginType === "STAFF" ? "/admindashboard" : "/dashboard";
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });

    // window.location.href = "/dashboard";

    return false;
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-4 col-lg-5 col-md-7 mx-auto mt-5">
          <div className="card radius-10">
            <div className="card-body p-4">
              <div className="text-center">
                <h4>Sign In</h4>
                <p>Sign In to your account</p>
              </div>
              <form className="form-body row g-3" onSubmit={handleLogin}>
                <div className="col-12">
                  <label for="inputEmail" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    required
                    className="form-control"
                    id="inputEmail"
                    autoFocus
                    required
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="col-12">
                  <label for="inputPassword" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="inputPassword"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-check form-switch d-none">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckRemember"
                    />
                    <label
                      className="form-check-label"
                      for="flexSwitchCheckRemember"
                    >
                      Remember Me
                    </label>
                  </div>
                </div>
                <div className="col-12 col-lg-6 text-end">
                  <a href="#">Forgot Password?</a>
                </div>
                <div className="col-12 col-lg-12">
                  <div className="d-grid">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleLogin}
                    >
                      Sign In
                    </button>
                  </div>
                </div>
                <div className="col-12 col-lg-12">
                  <div className="d-grid">
                    <select
                      className="form-select mb-3"
                      aria-label="Default select example"
                      value={loginType}
                      onChange={(e) => setLoginType(e.target.value)}
                    >
                      <option value={""}>Choose Login Type</option>
                      <option value={"TENANT"}>Tenant</option>
                      <option value={"STAFF"}>MRE - Staff Login</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
