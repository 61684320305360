import React from "react";
import { HeaderSecton } from "./HeaderSecton";

export const Navbar = () => {
  var userData = sessionStorage.getItem("userData");

  //return navigate("/login");
  userData = JSON.parse(userData);
  var loginas = userData["loginas"];

  return (
    <>
      <aside className="sidebar-wrapper" data-simplebar="true">
        <div className="sidebar-header">
          <div>
            <img
              src="assets/images/logo-icon-2.png"
              className="logo-icon"
              alt="logo icon"
            />
          </div>
          <div>
            <h4 className="logo-text">MRE-FMS</h4>
          </div>
          <div className="toggle-icon ms-auto">
            <ion-icon name="menu-sharp"></ion-icon>
          </div>
        </div>
        {/* <!--navigation--> */}
        <ul className="metismenu" id="menu">
          {loginas === "TENANT" ? (
            <>
              <li>
                <a href="/dashboard" className="">
                  <div className="parent-icon">
                    <ion-icon name="home-sharp"></ion-icon>
                  </div>
                  <div className="menu-title">Dashboard</div>
                </a>
              </li>
              <li>
                <a href="/myworkorders" className="">
                  <div className="parent-icon">
                    <ion-icon name="create-sharp"></ion-icon>
                  </div>
                  <div className="menu-title">My Workorder</div>
                </a>
              </li>
            </>
          ) : null}{" "}
          {loginas === "STAFF" ? (
            <>
              <li className="menu-label">Admin Management</li>
              <li>
                <a href="/admindashboard" className="">
                  <div className="parent-icon">
                    <ion-icon name="home-sharp"></ion-icon>
                  </div>
                  <div className="menu-title">Dashboard</div>
                </a>
              </li>
              <li>
                <a href="/allworkorders" className="">
                  <div className="parent-icon">
                    <ion-icon name="hammer-sharp"></ion-icon>
                  </div>
                  <div className="menu-title">All Workorder</div>
                </a>
              </li>
              <li>
                <a className="" href="/tenants">
                  <div className="parent-icon">
                    <ion-icon name="people-circle-sharp"></ion-icon>
                  </div>
                  <div className="menu-title">Tenants</div>
                </a>
              </li>
              <li>
                <a className="" href="/buildings">
                  <div className="parent-icon">
                    <ion-icon name="business-sharp"></ion-icon>
                  </div>
                  <div className="menu-title">Buildings</div>
                </a>
              </li>
              <li>
                <a className="" href="/employees">
                  <div className="parent-icon">
                    <ion-icon name="people-sharp"></ion-icon>
                  </div>
                  <div className="menu-title">Employees</div>
                </a>
              </li>
            </>
          ) : null}
          <li>
            <a className="" href="/logout">
              <div className="parent-icon">
                <ion-icon name="log-out-sharp"></ion-icon>
              </div>
              <div className="menu-title">Logout</div>
            </a>
          </li>
        </ul>
        {/* <!--end navigation--> */}
      </aside>
      <HeaderSecton />
    </>
  );
};
