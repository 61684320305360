import React, { useEffect, useState } from "react";
import { Navbar } from "../Navbar";
import { BackToTop } from "../BackToTop";
import Modal from "../Modal";
import ModalLauncher from "../ModalLauncher";
import "./modal.css";
import $ from "jquery";
import Select from "react-select";
import {
  ALL_WORKORDER,
  EMPLOYEE_DropDown,
  EMPLOYEE_DropDownSelected,
  SAVE_WORKORDER,
  SCHEDULE_WORKORDER,
  WORKORDER_STATUS,
} from "./constants";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import ReactPaginate from 'react-paginate';

import DataTable from 'react-data-table-component';
import { ThreeDots } from "react-loader-spinner";

export const Allworkorders = () => {
  const [show, setShow] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [woTitle, setWoTitle] = useState("");
  const [woDescription, setWoDescription] = useState("");
  const [allworkorders, setAllworkorders] = useState([]);
  const [workorderid, setWorkorderid] = useState(0);
  const [assignTo, setAssignTo] = useState("");
  const [inspectionDate, setInspectionDate] = useState("");
  const [workType, setWorkType] = useState("AC Supervisor");
  const [woMaterial, setWoMaterial] = useState("");
  const [woStatus, setWoStatus] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const navigate = useNavigate();
  const [empselected, setEmpselected] = useState([]);
  const [empOptions, setEmpOptions] = useState([]);
  const [tenantId, setTenantId] = useState(0);
  let emp_options = [];
  const [preSelected, setPreSelected] = useState([]);
  
  const [columns, setColumns] = useState([])
  const [tblData, setTblData] = useState([])
   
  const [loading, setLoading] = useState(false)

  let loginUserData = "";
  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };

  const showStatusModal = () => {
    setShowStatus(true);
  };
  const hideStatusModal = () => {
    setShowStatus(false);
  };
  const saveWorkorder = () => {
    var postData = {
      tenant_id: 1,
      woTitle: woTitle,
      woDescription: woDescription,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };
    axios
      .post(SAVE_WORKORDER, postData, axiosConfig)
      .then((result) => {
        var res = result.data;
        if (res.status === false) {
          Swal.fire(res.msg);
          return false;
        }
        console.log("RESPONSE RECEIVED: ", res);
        Swal.fire(res.msg);
        setWoTitle("");
        setWoDescription("");

        hideModal();
        getAllWorkOrders();
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  const updateWorkorder = () => {
    // if (assignTo.trim().length < 3) {
    //   Swal.fire("please enter assignTo <br />minimum 3 characters");
    //   return false;
    // }

    
    // console.log(startDate.toLocaleString());    return false;

    var postData = {
      tenant_id: tenantId,
      workorder_id: workorderid,
      assign_to: empselected,
      inspectionDate: startDate.toLocaleString(),
      workType: workType,
      woMaterial: woMaterial,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };
    axios
      .post(SCHEDULE_WORKORDER, postData, axiosConfig)
      .then((result) => {
        var res = result.data;
        if (res.status === false) {
          Swal.fire(res.msg);
          return false;
        }
        console.log("RESPONSE RECEIVED: ", res);
        Swal.fire(res.msg);
        setAssignTo("");
        setStartDate(new Date());
        setWoMaterial("");
        setWorkType("");

        hideModal();
        getAllWorkOrders();
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  const getAllWorkOrders = () => {
    setLoading(true)
    var postData = {
      tenant: 1,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };
    axios
      .post(ALL_WORKORDER, postData, axiosConfig)
      .then((result) => {
        setLoading(false)
        var res = result.data;

        setAllworkorders(res);
        
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  const createEmpDropdown = () => {
    emp_options = [];
    var postData = {
      tenant: null,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };
    axios
      .post(EMPLOYEE_DropDown, postData, axiosConfig)
      .then((result) => {
        var res = result.data;
        emp_options = [];
        res.map((emp, idx) => {
          // console.log("emp", emp["id"], "idx", emp["fullname"]);
          emp_options.push({
            label: "[" + emp["fileno"] + "] " + emp["fullname"],
            value: emp["id"],
          });
        });
        setEmpOptions([]);
        setEmpOptions(emp_options);
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  const getSelectedWoEmployee = (workorderid) => {
    setWorkorderid(workorderid);
    var postData = {
      workorder_id: workorderid,
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };
    axios
      .post(EMPLOYEE_DropDownSelected, postData, axiosConfig)
      .then((result) => {
        var res = result.data;
        emp_options = [];
        for (const preemp of res) {
          var empid = preemp["employee_id"];
          for (const nod of empOptions) {
            if (nod["value"] === empid) {
              emp_options.push(nod);
            }
          }
        }
        console.log(emp_options);
        preSelected(emp_options);
        // setEmpOptions(emp_options);
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  const  UpdateWorkorder = async (woid) => {
    Swal.fire({
      title: "Choose the status from the list?",
      // showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Update",
      input: "select",
      inputOptions: {
       
          "OPEN": "OPEN",
          "IN_PROGRESS": "IN PROGRESS",
          "ON_HOLD": "ON HOLD",
          "CANCELLED": "CANCELLED",
          "CLOSED": "CLOSED",
          "COMPLETED": "COMPLETED",
        
      },
      inputPlaceholder: "Select Status",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed ) {
        
        if (result.value === "") {
          Swal.fire("No Status selected \n Please select a status from the list", "", "question");
        } else {


                
          var postData = {
            workorder_id: woid,
            workorder_status: result.value
          };
          let axiosConfig = {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          };
          axios
            .post(WORKORDER_STATUS, postData, axiosConfig)
            .then((result) => {
              var res = result.data;
              
              Swal.fire(res.msg)
              getAllWorkOrders()
              
              
            })
            .catch((err) => {
              console.log("AXIOS ERROR: ", err);
            });


        }

      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
    
  }

  useEffect(() => {
    
    loginUserData = JSON.parse(sessionStorage.getItem("userData"));
    if (!loginUserData) {
      navigate("/login");
    }
    setTimeout(getAllWorkOrders(), 1500);
    createEmpDropdown();


    var cols = [
      {
        name: 'id',
        selector: row => row.id,
      },
      {
        name: 'Building',
        selector: row => row.building.name,
        sortable: true,
      },
      {
        name: 'Flat',
        selector: row => row.tenant.flatno,
      },
      {
        name: 'Requested Work',
        selector: row => row.title,
      },
      {
        name: 'Description',
        selector: row => row.description,
      },
      {
        name: 'WorkType',
        selector: row => row.work_type,
      },
      {
        name: 'Schedule DT',
        selector: row => row.inspection_dt,
      },
      {
        name: 'Material',
        selector: row => row.material,
      },
      {
        name: 'Completed On',
        // selector: row => row.building.name,
      },
      {
        name: 'Status',
        selector: row => row.work_status,
      },
      {
        name: 'Created',
        selector: row => row.created_at.substring(0, 10),
      },
    ];
  
    setColumns(cols);
    const data = [
      {
      id: 1,
      title: 'Beetlejuice',
      year: '1988',
    },
    {
      id: 2,
      title: 'Ghostbusters',
      year: '1984',
    },
  ]
  setTblData(data);

  }, []);

  $(".btnform").on("click", function (event) {
    $("#frm-workorder").trigger("reset");
  });

  return (
    <>
      <div className="wrapper">
        <Navbar />
        <div className="page-content-wrapper">
          <div className="page-content">
            {/* <!--start breadcrumb--> */}
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
              <div className="breadcrumb-title pe-3">Pages</div>
              <div className="ps-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0 p-0 align-items-center">
                    <li className="breadcrumb-item">
                      <a href="#">
                        <ion-icon name="home-outline"></ion-icon>
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      WorkOrders
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            {/* <!--end breadcrumb--> */}

            {/* <div className="card radius-10">
            <div className="card-body">
              <h4>Dashobard</h4>                
              
            </div>
          </div> */}

            <div className="card">
              <div className="card-body">
                <button
                  type="button"
                  className="btn btn-sm btn-outline-success px-5 btnform d-none"
                >
                  <ion-icon
                    name="circle-plus-sharp"
                    role="img"
                    className="md hydrated"
                    aria-label="pricetags sharp"
                  ></ion-icon>{" "}
                  Request Work
                </button>
                <div className="d-flex align-items-center">
                  <h5 className="mb-0">Work Orders</h5>
                  {/* <form className="ms-auto position-relative">
                    <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                      <ion-icon name="search-sharp"></ion-icon>
                    </div>
                    <input
                      className="form-control ps-5"
                      type="text"
                      placeholder="search"
                    />
                  </form> */}
                </div>
                <div className="table-responsive mt-3">

                {/* <DataTable
                  columns={columns}
                  data={allworkorders}
                />
<hr /><hr /> */}
                  <table className="table align-middle mb-0 table-bordered">
                    <thead className="table-light">
                      <tr>
                        <th>ID</th>
                        <th>Building</th>
                        <th>Flat</th>
                        <th>Requested Work</th>
                        <th>Description</th>
                        <th>WorkType</th>
                        
                        
                        <th>Schedule&nbsp;DT</th>
                        <th>Material</th>
                        <th>Completed&nbsp;On</th>
                        <th>Status</th>
                        <th>Created</th>
                        <th>Action</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                    {loading ? (
                      <ThreeDots
                      visible={true}
                      height="80"
                      width="80"
                      color="#4fa94d"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      /> ) :  
                      allworkorders.map((workorder, idx) => (
                        <tr>
                          <td>{workorder.id}</td>
                          <td>{workorder.building.name}</td>
                          <td>{workorder.tenant.flatno}</td>
                          <td>{workorder.title}</td>
                          <td>{workorder.description}</td>
                          <td>{workorder.work_type}</td>                          
                          
                          <td>{workorder.inspection_dt}</td>
                          <td>{workorder.material}</td>

                          <td>-</td>
                          <td>
                            <span className="badge bg-light-danger text-danger w-100">
                            {workorder.work_status}
                            </span>
                          </td>
                          <td>{workorder.created_at.substring(0, 10)}</td>
                          <td>
                            <div class="btn-group">
                              <button
                                type="button"
                                class="btn btn-outline-primary"
                              >
                                Action
                              </button>
                              <button
                                type="button"
                                class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <span class="visually-hidden">
                                  Toggle Dropdown
                                </span>
                              </button>
                              <ul class="dropdown-menu">
                                <li>
                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    onClick={(e) => {
                                      setWorkorderid(workorder.id);
                                      // console.log("wo", workorderid);
                                      setTenantId(workorder.tenant_id);
                                      showModal();
                                      getSelectedWoEmployee(workorder.id);
                                    }}
                                  >
                                    Schedule W.O
                                  </a>
                                </li>

                                <li>
                                  <hr class="dropdown-divider" />
                                </li>
                                <li>
                                  <a class="dropdown-item" href="#"
                                  onClick={(e) => {
                                    setWorkorderid(workorder.id);
                                    // console.log("wo", workorderid);
                                    setTenantId(workorder.tenant_id);
                                    //showModal();
                                    UpdateWorkorder(workorder.id);
                                  }}
                                  >
                                    Update Status
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </td>
                          
                        </tr>
                        
                      ))}
                    </tbody>
                  </table>
                  <div style={{ minHeight: "150px" }}>&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BackToTop />
        <div className="container-fluid">
          <Modal
            show={showStatus}
            hideModal={hideStatusModal}
            callback={updateWorkorder}
            title="Schedule Workorder"
          ></Modal>
          <Modal
            show={show}
            hideModal={hideModal}
            callback={updateWorkorder}
            title="Schedule Workorder"
          >
            <form
              id="frm-workorder"
              onSubmit={() => {
                return false;
              }}
            >
              <div className="col-12 mb-3">
                <label className="form-label">Assigned To</label>
                {/* <MultiSelect
                  options={empOptions}
                  value={empselected}
                  onChange={setEmpselected}
                  labelledBy="Select"
                /> */}
                <Select
                  defaultValue={preSelected}
                  isMulti
                  name="colors"
                  options={empOptions}
                  onChange={setEmpselected}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
                {/* <input
                  type="text"
                  className="form-control"
                  value={assignTo}
                  onChange={(e) => setAssignTo(e.target.value)}
                /> */}
              </div>
              <div className="col-12 mb-3">
                <label className="form-label">Schedule Date</label>
                <br />
                <DatePicker
                  selected={startDate}
                  // dateFormat="yyyy/MM/dd h:MM a"
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  className="form-control "
                  dateFormat="Pp"
                  icon="fa fa-calendar"
                />
              </div>
              <div className="col-12">
                <label className="form-label">Work Type</label>
                <select
                  className="form-select mb-3"
                  aria-label="Default select example"
                  value={workType}
                  onChange={(e) => setWorkType(e.target.value)}
                >
                  <option value={"AC Supervisor"}>AC Supervisor</option>
                  <option value={"MEP Supervisor"}>MEP Supervisor</option>
                  <option value={"Property Management"}>
                    {" "}
                    Property Management
                  </option>
                  <option value={"Fitout Supervisor"}>
                    {" "}
                    Fitout Supervisor
                  </option>
                </select>
              </div>
              <div className="col-12">
                <label className="form-label">Material</label>
                <textarea
                  className="form-control"
                  onChange={(e) => setWoMaterial(e.target.value)}
                  value={woMaterial}
                ></textarea>
              </div>
            </form>
          </Modal>
        </div>

        <div className="overlay"></div>
      </div>
    </>
  );
};
