import React, { useEffect, useState } from 'react'
import Modal from '../Modal';
import { BUILDING_LIST, BUILDING_UNITS, BUILDING_UNITS_SAVE, TENANT_UNIT_SAVE, TENANT_UNITS } from '../fms/constants';
import axios from 'axios';
import Swal from 'sweetalert2'
import { BackToTop } from '../BackToTop';
import { Navbar } from '../Navbar';
import { useSearchParams } from 'react-router-dom';

export const TenantUnits = (tenant) => {
  const [searchParams, setSearchParams] = useSearchParams();

    const [unitName, setUnitName] = useState("")
    const [show, setShow] = useState(false);
    const [allunits, setAllunits] = useState([]);
    const [unitlist, setUnitlist] = useState([])

    const [buildingUnitId, setBuildingUnitId] = useState(0)
    const [buildingId, setBuildingId] = useState(0)
    const [selectedTenant, setSElectedTenant] = useState(0);
    const [building, setBuilding] = useState("");
    const [buildinglist, setBuildinglist] = useState([]);
    const [flatno, setFlatno] = useState(0)
    const [showUnitModal, setShowUnitModal] = useState(false);

    const [unitId, setUnitId] = useState(0)
    const [selectedBuildingId, setSelectedBuildingId] = useState(0)

    console.log('TTTT___', sessionStorage.getItem("tenantRef"))

    const showModal = () => {
        setShow(true);
      };
      const hideModal = () => {
        setShow(false);
      };

      const getBuildings = () => {
        var postData = {
          tenant: 1,
        };
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
          },
        };
        axios
          .post(BUILDING_LIST, postData, axiosConfig)
          .then((result) => {
            var res = result.data;
    
            setBuildinglist(res);
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
          });
      };

      const getBuildingUnits = (curBuilding) => {
        console.log("ET",building, curBuilding)
        setBuilding(curBuilding)
        setFlatno(unitId)
        var postData = {
            building: curBuilding,        
          };
          let axiosConfig = {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          };
          axios
            .post(BUILDING_UNITS, postData, axiosConfig)
            .then((result) => {
            var res = result.data;
            
            if (res.status === false) {
                Swal.fire(res.msg);    
                return false;
              }
            setUnitlist(res)
             
            })
            .catch((err) => {
              console.log("AXIOS ERROR: ", err);
            });
        
      }
      const getUnit =async (curBuilding) => {
        
        getBuildingUnits(curBuilding);
        if( await setBuilding(curBuilding)) 
        setFlatno(unitId);
        
      }
      const getAllMyUnits = (tenantid) => {
        
        
        var postData = {
            tenant_id: tenantid,
          };
          let axiosConfig = {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          };
          axios
            .post(TENANT_UNITS+"?r="+Math.random() , postData, axiosConfig)
            .then((result) => {
            var res = result.data;
            
            if (res.status === false) {
                Swal.fire(res.msg);    
                return false;
              }
            setAllunits(res)
             
            })
            .catch((err) => {
              console.log("AXIOS ERROR: ", err);
            });
        
      }
      const handleBuilding = (e) => {
        setBuilding(e.target.value);
        console.log("ET",e.target.value)
        getBuildingUnits(e.target.value);
      };

      const saveUnit = () => {

        var postData = {
            tenant_id: selectedTenant,        
            flatno: flatno,
            building_id: building

          };
          let axiosConfig = {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
            },
          };
          axios
            .post(TENANT_UNIT_SAVE+"?r="+Math.random() , postData, axiosConfig)
            .then((result) => {
            var res = result.data;
            Swal.fire(res.msg);
            if (res.status === false) {                
                return false;
              }
              getAllMyUnits(selectedTenant)
            hideModal()
             
            })
            .catch((err) => {
              console.log("AXIOS ERROR: ", err);
            });
        
      }

      useEffect(() => {
        setSElectedTenant(searchParams.get("tenant"))

         setAllunits([])
        
        getAllMyUnits(searchParams.get("tenant"));
        getBuildings();
        //getBuildingUnits();
      }, []);
 
      
        
  return (
    <>
    <div className="wrapper">
        <Navbar />
        <div className="page-content-wrapper">
          <div className="page-content">
            {/* <!--start breadcrumb--> */}
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
              <div className="breadcrumb-title pe-3">Pages</div>
              <div className="ps-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0 p-0 align-items-center">
                    <li className="breadcrumb-item">
                      <a href="#">
                        <ion-icon name="home-outline"></ion-icon>
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      <a href='/Tenants'>Tenants</a>
                    </li>
                    
                    <li className="breadcrumb-item active" aria-current="page">
                      Units
                    </li>
                    
                  </ol>
                </nav>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <button
                  type="button"
                  onClick={showModal}
                  className="btn btn-sm btn-success px-5 btnform"
                >
                  <ion-icon
                    name="circle-plus-sharp"
                    role="img"
                    className="md hydrated"
                    aria-label="pricetags sharp"
                  ></ion-icon>{" "}
                  Assign New Unit
                </button>
                <hr />
                <table className="table align-middle mb-0 table-bordered">
                    <thead className="table-light">
                      <tr>
                        <th>ID</th>
                        
                        <th>Unit</th>
                        <th>Building</th>
                        <th>Action</th>                        
                      </tr>
                    </thead>
                    <tbody>
                    { allunits.length === 0 ? (
                        <tr>
                            <td colSpan={8} className='text-center' ><h5>No Units found</h5></td>
                        </tr>
                    ) : (                    
                    allunits.map((unit, idx) => (
                      <tr>
                        <td>{unit.id}</td>
                        <td>{unit.unit?.name}</td>
                        <td>{unit.building?.name}</td>
                        <td>
                        <div class="btn-group">
                        <button type="button" class="btn btn-outline-primary">Action</button>
                        <button type="button" class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">	<span class="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul class="dropdown-menu">
                          <li>
                            <a class="dropdown-item" href="#" onClick={(e) => {setBuildingUnitId(unit.id); setSelectedBuildingId(unit.building_id); setUnitId(unit.unit_id); getUnit(unit.building_id); showModal()}}>Update</a>
                          </li>
                          
                          
                        </ul>
                      </div>
                        
                        </td>                        
                      </tr>
                        )))}
                      
                    </tbody>
                  </table>
                  </div>
            </div>
          </div>
        </div>
        <BackToTop />
                  <div className="container-fluid">        
          <Modal show={show} hideModal={hideModal} callback={saveUnit} title={"Unit Form"}>
            
          <div className="col-12">
                <label className="form-label">Building</label>
                <select
                  className="form-select mb-3 single-select"
                  aria-label="Default select example"
                  value={building}
                  onChange={handleBuilding}
                >
                  <option value={""}>Select Building</option>
                  {buildinglist.map((option, idx) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12  mb-3">
                <label className="form-label">FlatNo.</label>
                <select
                  className="form-select mb-3 single-select"
                  aria-label="Default select example"
                  value={flatno}
                  onChange={(e) => setFlatno(e.target.value)}
                >
                  <option value={""}>Select Unit</option>
                  {unitlist.map((option, idx) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className="col-12">
                <label className="form-label">Unit Name</label>
                <input type="text" className="form-control"
                value={unitName}
                onChange={(e) => setUnitName(e.target.value)}
                />
              </div> */}
            
          </Modal>
                  </div>
                  <div className="overlay"></div>
      </div>
    </>
  )
}
