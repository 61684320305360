import React, { useEffect, useState } from "react";

import Modal from "./Modal";
import ModalBig from "./ModalBig";
import ModalLauncher from "./ModalLauncher";
import "./fms/modal.css";
import $ from "jquery";
import { ALL_WORKORDER, BUILDING_GET, BUILDING_LIST, BUILDING_SAVE, CITIES_LIST, DEFAULT_COUNTRY, SAVE_WORKORDER } from "./fms/constants";
import axios from 'axios'
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import { Navbar } from "./Navbar";
import { BackToTop } from "./BackToTop";
import { BuildingUnits } from "./BuildingUnits";
import { ThreeDots } from "react-loader-spinner";
export const Buildings = () => {
  
    const navigate = useNavigate();
  const [tenantId, setTenantId] = useState(0)
  const [show, setShow] = useState(false);
  const [showUnitModal, setShowUnitModal] = useState(false);

  const [fBuilding, setFBuilding] = useState("");
  const [fCity, setFCity] = useState("")
  const [fAddress, setFAddress] = useState("")
  const [fLandline, setFLandline] = useState("")
  const [fTAX, setFTAX] = useState("")

  const [woDescription, setWoDescription] = useState("");
  const [allbuildings, setAllbuilding] = useState([])
  const [buildingId, setBuildingId] = useState(0)
  const [dropdownCities, setDropdownCities] = useState([])

  const [loadUnits, setLoadUnits] = useState(null)
  const [loading, setLoading] = useState(false)
  const [userdata, setUserdata] = useState([])

    let loginUserData = "";
  const showModal = () => {
    setShow(true);
  };
  const hideModal = () => {
    setShow(false);
  };

  const showUnitsModal = () => {
    setShowUnitModal(true);
  };
  const hideUnitsModal = () => {
    setShowUnitModal(false);
  };

  const loadUnitsComponent = async (building_id) => {
    const module = import('./BuildingUnits');
    const {BuildingUnits} = await module;
    setLoadUnits(null)
    console.log('bid', building_id);
    setLoadUnits(<BuildingUnits useEffect  building={{ building_id: building_id }}  />);
  
  }

  const saveBuilding = () => {
    var postData = {
        id: buildingId,
        name: fBuilding,
      city: fCity,
      address: fAddress,
      landline: fLandline,
      taxvat: fTAX
    };
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
      },
    };
    axios
      .post(BUILDING_SAVE, postData, axiosConfig)
      .then((result) => {
        var res = result.data;
        if (res.status === false) {
          Swal.fire(res.msg);
          return false;
        }
        console.log("RESPONSE RECEIVED: ", res);
        Swal.fire(res.msg);
        setFBuilding("");
        
        
        hideModal();
        getAllBuildings();
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      });
  };

  const getAllBuildings = () => {
    setLoading(true);
    var postData = {
        tenant: 1,        
      };
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
        },
      };
      axios
        .post(BUILDING_LIST, postData, axiosConfig)
        .then((result) => {
        var res = result.data;
          
        setAllbuilding(res)
        setLoading(false);
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        });
  }

  const getBuildingInfo = (building_id) => {
    setBuildingId(building_id);
    var postData = {
        building_id: building_id,
      };
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
        },
      };
      axios
        .post(BUILDING_GET, postData, axiosConfig)
        .then((result) => {
        var res = result.data;
          setFBuilding(res.name);
           setFCity(res.city_id)
           setFAddress(res.address)
           setFLandline(res.landline)
           setFTAX(res.vat_tax_no)
         
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        });
  }

  const getStates  = () => {
    var postData = {
        country_id: DEFAULT_COUNTRY,        
      };
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
        },
      };
      axios
        .post(CITIES_LIST, postData, axiosConfig)
        .then((result) => {
        var res = result.data;
          
        setDropdownCities(res)
         
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        });
  }

  useEffect(() => {
    loginUserData = JSON.parse(sessionStorage.getItem("userData"));
    if (!loginUserData) {      
      navigate("/login"); 
    }
    if (!loginUserData['content']) {
        navigate("/login");
    }
    var tid = loginUserData['content']['id'];
    setTenantId(tid)

    
    setUserdata(loginUserData);
    setTimeout(getAllBuildings(), 1500);
    setTimeout(getStates, 1500);
  }, []);
  $(".btnform").on("click", function (event) {
    $("#frm-workorder").trigger("reset");
  });

  return (
    <>
      <div className="wrapper">
        <Navbar />
        <div className="page-content-wrapper">
          <div className="page-content">
            {/* <!--start breadcrumb--> */}
            
            {/* <!--end breadcrumb--> */}

            {/* <div className="card radius-10">
            <div className="card-body">
              <h4>Dashobard</h4>                
              
            </div>
          </div> */}

            <div className="card">
              <div className="card-body">
              <div className="text-right" style={{ textAlign: "right" }}>
                <button
                  type="button"
                  onClick={(e) => {showModal(); setBuildingId(0) }}
                  className="btn btn-sm btn-success btnform"
                >
                  <ion-icon
                    name="add-circle"
                    role="img"
                    className="md hydrated"
                    aria-label="pricetags sharp"
                  ></ion-icon>
                  Add New Building
                </button> 
                </div>
                <div className="table-responsive mt-3">
                  <table className="table align-middle mb-0 table-bordered">
                    <thead className="table-light">
                      <tr>
                        <th>ID</th>
                        <th>Building</th>
                        <th>City</th>
                        {/* <th>Country</th> */}
                        <th>Address</th>
                        <th>Landline</th>
                        <th>VAT/TAX</th>
                        <th>Action</th>
                        {/* <th>WorkStatus</th> */}
                      </tr>
                    </thead>
                    <tbody>
                    {loading ? (
                      <ThreeDots
                      visible={true}
                      height="80"
                      width="80"
                      color="#4fa94d"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      /> ) : 
                    allbuildings.map((building, idx) => (
                      <tr>
                        <td>{building.id}</td>
                        <td>{building.name}</td>
                        <td>{building.city?.name}</td>
                        {/* <td>{building.country.country_enName}</td> */}
                        <td>{building.address}</td>
                        <td>{building.landline}</td>
                        <td>{building.vat_tax_no}</td>
                        <td>
                        <div class="btn-group">
                        <button type="button" class="btn btn-outline-primary">Action</button>
                        <button type="button" class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">	<span class="visually-hidden">Toggle Dropdown</span>
                        </button>
                        <ul class="dropdown-menu">
                          <li><a class="dropdown-item" href="#" onClick={(e) => {showModal(); getBuildingInfo(building.id)}}>Update Info</a>
                          </li>
                          
                          <li>
                            <hr class="dropdown-divider" />
                          </li>
                          <li><a class="dropdown-item" href="#" onClick={(e) => {setBuildingId(building.id); showUnitsModal(); loadUnitsComponent(building.id) }}>Manage Units</a>
                          </li>
                        </ul>
                      </div>
                        
                        </td>                        
                      </tr>
                        ))}
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BackToTop />
        <div className="container-fluid">
        <ModalBig show={showUnitModal} hideModal={hideUnitsModal} callback={""} title={"Building Units"}>
            {loadUnits}
                        {/* <BuildingUnits building={{ building_id: buildingId }}  /> */}
            </ModalBig>
          <Modal show={show} hideModal={hideModal} callback={saveBuilding} title={"Update Building"}>
            
              
              <div className="col-12">
                <label className="form-label">Building Name</label>
                <input type="text" className="form-control"
                value={fBuilding}
                onChange={(e) => setFBuilding(e.target.value)}
                /> 
              </div>
              <div className="col-12">
                <label className="form-label">City</label>
                <select
                  className="form-select mb-3"
                  aria-label="Default select example"
                  value={fCity}
                  onChange={(e) => setFCity(e.target.value)}
                >
                    <option value={""}>Select State</option>
                {dropdownCities.map((city, idx) => (
                    <option value={city.id}>{city.name}</option>
                ))}
                </select>
              </div>
              <div className="col-12">
                <label className="form-label">Address</label>
                <input type="text" className="form-control"
                value={fAddress}
                onChange={(e) => setFAddress(e.target.value)}
                />
              </div>
              
              <div className="col-12">
                <label className="form-label">Landline</label>
                <input type="text" className="form-control"
                value={fLandline}
                onChange={(e) => setFLandline(e.target.value)}
                />
              </div>
              <div className="col-12">
                <label className="form-label">VAT/TAX</label>
                <input type="text" className="form-control"
                value={fTAX}
                onChange={(e) => setFTAX(e.target.value)}
                />
              </div>
            
          </Modal>
        </div>

        <div className="overlay"></div>
      </div>
    </>
  );

}
